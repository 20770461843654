
import {computed, defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {useRoute} from "vue-router";
import {dateConvert} from "@/core/filters/datatime";
import {Field} from "vee-validate";

import ItemAddNew from "@/components/hotel/AvailabilityAddNew.vue";
import {commonf} from "@/core/filters/common";

interface newSrcData {
    srcRoom: number;
    srcMarket: string;
    srcPeriod: string;
}

export default defineComponent({
    name: "overview",
    components: {
        ItemAddNew,
        Field,
    },
    setup() {
        onMounted(() => {
            setCurrentPageBreadcrumbs("Otel Reklam Katkı", ["Otel"]);
        });

        const route = useRoute();
        const id = route.params.id;


        store.dispatch(Actions.HOTEL_ACTIONS_DETAILS, {ID: id,});

        const myDetails = computed(() => {
            return store.getters.hotelActionsDetails;
        });

        let chkRoom =false;
        store.dispatch(Actions.HOTEL_ROOMS_LIST, {HotelID: id, is_active: "active",});
        const myRoomsList = computed(() => {
            let tmpArr = store.getters.hotelRoomsList;
            //console.log(tmpArr.data[0].Title);
            if(tmpArr.data!==undefined && chkRoom == false){
                setData(tmpArr);

                chkRoom=true;
            }
            return tmpArr;
        });


        const setData = (tmpArr) => {
            newSrcData.value.srcRoom =  tmpArr.data[0].ID;
        }
        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });
        const newSrcData = ref<newSrcData>({
            //srcRoom: (myRoomsList.value.data!==undefined) ? (myRoomsList.value.data[0].ID) : '',
            srcRoom: 0,
            srcMarket: "TURK",
            srcPeriod: "",
        });
        //console.log(payload);


        let myCalendarStartDate = dateConvert.method.getTodayYMD();
        myCalendarStartDate = dateConvert.method.addMonthToDateYMD(myCalendarStartDate,0);
        let myCalendarFinishDate = dateConvert.method.addMonthToDateYMD(myCalendarStartDate,12);

        let myChkRoom =-1;
        let myChkMarket = "";
        const myList = computed(() => {

            const payload3 = {
                HotelID: id,
                Room: newSrcData.value.srcRoom,
                Market: newSrcData.value.srcMarket,
                StartDate: myCalendarStartDate,
                FinishDate: myCalendarFinishDate,
            }
            if(newSrcData.value.srcRoom!=myChkRoom || newSrcData.value.srcMarket!=myChkMarket) {
                store.dispatch(Actions.HOTEL_AVAILABLE_STATUS, payload3);
                myChkRoom = newSrcData.value.srcRoom
                myChkMarket = newSrcData.value.srcMarket;
            }
            return store.getters.hotelAvailability;
        });

        const filteredDate = (value) => {
            if (myList.value.data != null) {
                return (myList.value.data).filter(dateItem =>
                    dateItem.DATE == value
                );
            } else {
                return true;
            }
        };



        function getDayInMonth(m) {
            let myDate = dateConvert.method.getTodayYM();
            let myNewDate = dateConvert.method.addMonthToDateYM(myDate, m);
            return dateConvert.method.getDayInMonth(myNewDate);
        }

        function getYearMonth(m) {
            let myDate = dateConvert.method.getTodayYM();
            let myNewDate = dateConvert.method.addMonthToDateYM(myDate, m);
            return dateConvert.method.getTodayYMtr(myNewDate);
        }

        function getCellDate(m, d) {
            let myDate = dateConvert.method.getTodayYM();
            let myNewDate = dateConvert.method.addDayMonthToDateYMD(myDate, m, d - 1);
            return myNewDate
        }

        function openAddNewModal() {
            let btn = (document.getElementById('addNewButton') as HTMLInputElement);
            btn.click();
        }

        let myClickCount = 0;
        let mySelectedAddDates = {
            'DATES': [],
            'FILTER': {}
        };

        function selectDate(date) {
            mySelectedAddDates['DATES'][myClickCount] = {
                "DATE": date
            };
            myClickCount++;
            if (myClickCount == 2) {
                mySelectedAddDates['FILTER'] = {
                    'StartDate': myCalendarStartDate,
                    'FinishDate': myCalendarFinishDate,
                    'Room': newSrcData.value.srcRoom,
                    'Market': newSrcData.value.srcMarket,
                    'HotelID': id,
                };
                console.log(mySelectedAddDates);
                store.dispatch(Actions.HOTEL_AVAILABLE_ADD_DATES, mySelectedAddDates);
                myClickCount = 0;
                openAddNewModal();
                mySelectedAddDates = {
                    'DATES': [],
                    'FILTER': {}
                };
            }

        }

        return {
            id,
            myDetails,
            myList,
            dateConvert,
            commonf,
            getDayInMonth,
            getYearMonth,
            openAddNewModal,
            myMarkets,
            selectDate,
            getCellDate,
            filteredDate,
            myRoomsList,
            newSrcData,
        };
    },
});
